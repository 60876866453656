<template>
    <div>
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="listFinishedText"
            @load="onLoad"
            style="margin-bottom:20px"
            >
            <div v-for="(item,index) in list" :key="index" class="schedule-comment-list">
                <div class="schedule-comment-list-nav">
                    <avatar :src="item.comment_avatar" color="#fff" :size="28"></avatar>
                    <div class="schedule-comment-list-name">
                        <div>{{item.comment_name}}</div>
                        <div class="schedule-comment-list-time">{{item.comment_at}}</div>
                    </div>
                </div>
                <van-rate v-if="item.commnet_rate" v-model="item.commnet_rate" allow-half
                                void-icon="star"
                                void-color="#eee"
                                :color="globalData.theme.color1" />
                <div class="schedule-comment-list-content">
                    {{item.comment_content}}
                </div>
                <div class="schedule-comment-list-img">
                    <van-image
                    v-for="(it,j) in item.comment_image"
                    :key="j"
                    @click="toPreview(item.comment_image,j)"
                    width="65"
                    height="65"
                    fit="contain"
                    :src="it"
                    />
                </div>
                <div @click="toLike(item)" class="schedule-comment-list-like">
                    <van-icon name="good-job-o" :color="item.commnet_is_liked?'#00cca2':''" />
                    <span :style="{marginLeft:'10px',color:item.commnet_is_liked?'#00cca2':''}">{{item.commnet_like_count}}</span>
                </div>
            </div>
            <van-empty v-if="list.length == 0 && !loading" description="暂无相关数据~" />
        </van-list>
        <div 
            class="float-ball"
            ref="ball"
            @touchstart.stop='handleTouchstart'
            @touchmove.stop='handleTouchmove'
            @touchend.stop='handleTouchend'
            @click.stop="show = true;isConfirm = false"
            :style="{top:`${moveTop}px`,left:`${moveLeft}px`}"
            >
            <van-icon color="#00cca2" name="add-o" />
        </div>

        <div class="affix-bar">
            <van-button type="primary" block @click="show = true;">添加点评</van-button>
        </div>
        <van-popup v-model="show" position="bottom" round :style="{ height: '75%' }" >
            <div class="schedule-comment-popup">
                <div class="schedule-comment-popup-nav">
                    <div @click="cancel">
                        <van-button size="small" type="default">取消</van-button>
                    </div>
                    <div>
                        <van-button :loading="isConfirm" @click="confirm" size="small" type="primary">确认</van-button>
                    </div>
                </div>
                <div class="schedule-comment-popup-form">

                    <van-form @submit="confirm" :colon='true'>
                        <van-field name="rate" allow-half label="评分">
                            <template #input>
                                <van-rate
                                v-model="rate"
                                allow-half
                                void-icon="star"
                                void-color="#eee"
                                :color="globalData.theme.color1"
                                />
                            </template>
                        </van-field>
                        <van-field
                            v-model="message"
                            rows="5"
                            autosize
                            label="文字评论"
                            name="content"
                            type="textarea"
                            maxlength="1000"
                            placeholder="请输入评论内容"
                            show-word-limit
                            class="survey-van-textarea-boeder"
                            :required='true'
                            :rules="[{ required: true, message: '请输入' }]"
                        />
                        <van-field 
                            name="image"
                            label="图片评论"
                            :required='false'
                            >
                            <template #input>
                                <van-uploader :after-read="e=>afterRead(e)" :before-delete='(e,b)=>beforeDelete(e,b)' multiple :max-count="9" v-model="imgList" />
                            </template>
                        </van-field>
                    </van-form>
                </div>
            </div>
        </van-popup>
        <loadings :loading='searchLoading' />
        <copyright v-show='!searchLoading' />


    </div>
</template>

<script>
    import upload from '@/utils/upload'
    import PageMixin from '@/mixins/page'
    import { Popup,Field,Empty,Form,Uploader,ImagePreview,Rate } from 'vant'
    import Avatar from 'vue-avatar'
    import Loadings from '@/components/loading'
    import Copyright from '@/components/Copyright'
    export default {
        name:'schedule-comment',
        data() {
            return {
                moveTop:415,
                moveLeft:317,
                show:false,
                loading:false,
                isConfirm:false,
                searchLoading:true,
                message:'',
                list:[],
                finished: false,
                listFinishedText: '没有更多了',
                pageSize: 10,
                rate: 5,
                pageCurrent: 1,
                book_id:'',
                comment_entity:'',
                imgList:[]
            }
        },
        components: {
            Copyright,
            [Form.name]: Form,
            [Rate.name]: Rate,
            [Empty.name]: Empty,
            [Field.name]: Field,
            [Popup.name]: Popup,
            [Uploader.name]: Uploader,
            [Avatar.name]: Avatar,
            loadings:Loadings,
        },
        mixins: [PageMixin],
        created () {
            this.book_id = this.$route.query.book_id
        },
        methods: {
            getList(){
                this.loading = true
                this.$api.schedule_comment({ 'per-page': this.pageSize, page: this.pageCurrent,book_id:this.$route.query.book_id})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.comment_entity = res._data.comment_entity
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            onLoad() {
                this.getList()
            },
            handleTouchstart(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchmove(e){
                e.preventDefault()
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            handleTouchend(e){
                this.moveTop = e.changedTouches[0].clientY - 20
                this.moveLeft = e.changedTouches[0].clientX - 20
            },
            afterRead(file,name) {
                this.$forceUpdate()
                let that = this
                this.isConfirm = true
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        file.path  = res.fileName
                    })
                    .catch(err => {
                        that.$toast({ message: '图片上传失败~', duration: 1500 })
                    })
                }else{
                    file.forEach(it=>{
                        that.isConfirm = true
                        upload({ file: it.file })
                            .then(res => {
                                it.path  = res.fileName
                            })
                            .catch(err => {
                                that.$toast({ message: '图片上传失败~', duration: 1500 })
                            })
                    })
                }
                setTimeout(()=>{
                    that.isConfirm = false
                },1500)
            },
            beforeDelete(file,detail,name){
                this.imgList.splice(detail.index,1)
                this.$forceUpdate()
                return true
            },
            toPreview(item,index){
                WeixinJSBridge.invoke("imagePreview", {
                    "urls":item,
                    "current": item[index]
                })
            },
            toLike(item){
                if(item.commnet_is_liked){
                    item.commnet_is_liked = false
                    item.commnet_like_count--
                }else{
                    item.commnet_is_liked = true
                    item.commnet_like_count++
                }
                this.$api.schedule_like_or_cancel({comment_id:item.comment_id})
                .then(res=>{})
                .catch(err=>{})
            },
            cancel(){
                this.message = ''
                this.show = false
            },
            confirm(){
                if(!this.message){
                    this.$toast('请输入评论内容～')
                    return false
                }
                let obj = {
                    comment_content:this.message,
                    comment_entity:this.comment_entity,
                    comment_image:[],
                    comment_rate: this.rate
                }
                if(this.imgList.length>0){
                    obj.comment_image = this.imgList.map((item)=>{return item.path})
                }
                this.loading = true
                this.isConfirm = true
                this.$api.schedule_addcomment(obj)
                .then(res=>{
                    this.finished = false
                    this.list = []
                    this.imgList = []
                    this.pageCurrent = 1
                    this.$toast('操作成功～')
                    this.getList()
                    this.message = ''
                    this.show = false
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .schedule-comment-list{
        width: 90%;
        background: #fff;
        margin: 0 auto;
        margin-top: 15px;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
        position: relative;
        &-nav{
            display: flex;
            align-items: center;
        }
        &-name{
            margin-left:15px;
        }
        &-time{
            font-size: 12px;
            color: #999;
        }
        &-content{
            margin-top:10px;
            word-break: break-all;
        }
        &-img{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .van-image{
                margin: 10px;
            }
        }
        &-like{
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            align-items: center;
        }
    }
    .schedule-comment-popup{
        &-nav{
            display: flex;
            justify-content: space-between;
            padding: 10px;
        }
    }
    .float-ball{
        position: fixed;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 2px #999;
        .van-icon{
            font-size: 0.8rem;
        }
    }
</style>

<style lang="less">
    .schedule-comment-popup-form{
        .van-cell{
            display: block;
        }
        .van-field__label{
            width: 100%;
        }
        .survey-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
    }
</style>